"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formValidate_1 = require("./formValidate");
// import {getCoords} from '../utils';
var StepsForm = /** @class */ (function () {
    function StepsForm(props) {
        var _this = this;
        this.showElt = function ($elt) { return $elt.classList.remove('hide'); };
        this.hideElt = function ($elt) { return $elt.classList.add('hide'); };
        this.showCurrentStep = function () {
            _this.steps.forEach(function (step, index) {
                index === _this.state.current ? _this.showElt(step) : _this.hideElt(step);
                _this.state.current === _this.state.amount - 1 ? _this.hideElt(_this.nextBtn) : _this.showElt(_this.nextBtn);
                if (_this.prevBtn)
                    _this.state.current === 0 ? _this.hideElt(_this.prevBtn) : _this.showElt(_this.prevBtn);
            });
        };
        this.validateCurrentStep = function () {
            var inputs = _this.steps[_this.state.current].querySelectorAll('.required');
            _this.formValidate.checkInputs(inputs);
            var errorField = _this.steps[_this.state.current].querySelector('.error');
            if (errorField) {
                _this.state.currentStatus = 'invalid';
                // window.scrollTo( 0, (getCoords(errorField).top+30) );
            }
            else {
                _this.state.currentStatus = 'valid';
            }
        };
        this.nextBtnHandler = function (event) {
            event.preventDefault();
            _this.validateCurrentStep();
            if (_this.state.currentStatus === 'valid') {
                _this.state.current = ++_this.state.current;
                _this.showCurrentStep();
            }
        };
        this.prevBtnHandler = function (event) {
            event.preventDefault();
            _this.state.current = --_this.state.current;
            _this.showCurrentStep();
        };
        this.init = function () {
            _this.showCurrentStep();
            _this.nextBtn && _this.nextBtn.addEventListener('click', _this.nextBtnHandler);
            _this.prevBtn && _this.prevBtn.addEventListener('click', _this.prevBtnHandler);
        };
        this.form = document.querySelector(props.formSelector);
        this.steps = this.form.querySelectorAll(props.stepsSelector);
        this.nextBtn = this.form.querySelector(props.nextBtnSelector);
        this.prevBtn = this.form.querySelector(props.prevBtnSelector);
        this.state = {
            current: 0,
            amount: this.steps.length,
            currentStatus: 'not verified'
        };
        this.formValidate = new formValidate_1.FormValidate({
            form: this.form,
            inputErrorTextSelector: '.field__error-text',
            formErrorTextSelector: '.form__error-text',
            botErrorTextSelector: '.form__error-bot-text'
        });
    }
    return StepsForm;
}());
exports.default = StepsForm;
