import "./polyfills";
import cardClick from './cardClick';
// import { modal } from './modal';
// import { formValidate } from './formValidate';
import {mobileMenu} from './menu';
import searchForm from './searchForm';
import accordion from './accordion';
import tabs from './tabs';
import partnersPopup from './partnersPopup.ts';
import matrikaReachGoals from './matrikaReachGoals.ts';
import calculator from './calculator';
import {initFormValidate, StepsForm, initAntiSpam} from './forms';
import trunk from './trunk.ts';

window.addEventListener('DOMContentLoaded', function(){

	trunk();

	calculator();

	matrikaReachGoals();

	partnersPopup('[data-comnponent="popup_on_click"]', ["popup_on_click", "IMG"], '[data-target="popup"]');

	tabs('tabs__nav', 'tabs__body');

	searchForm('.header__search-form', '.header__search-icon', '[data-component="header-menu"]', '.header__search-icon-close');


// Переход на страницу по клику на карточку в любом месте кроме корзины
	cardClick('[data-component="cards"]');

	accordion('[data-component="accordion"]');

// Выпадающее меню каталога
	// catalogMenu('[data-component="catalog_drop_menu_trigger"]', '[data-component="catalog_drop_menu"]');

// Опции и количество товара
// 1 - селектор кнопки "в корзину";
// 2 - селектор полей с опциями (input type=radio);
// 3 - cелектор поля для ввода количества товара (input);
	// putElement('#put-btn', '.prop-option', '.good-count');
// -------------------

// Изменение количества товаров в корзине
// 1 - Обёртка корзины
	// chengeAmount('#basket-cart');
// -------------------

//Мобильное меню
	// mobileMenu('[data-component="mobile_menu_trigger"]', '[data-component="mobile_menu"]');
// -------------------
 
// Модальные окна
	// modal('[data-modal]', '[data-modal-trigger]', '.object__name', 'order');
// -------------------
 
// Валидация форм

	if(document.querySelector('[data-component="steps_form"]')){
		const stepsForm = new StepsForm({
			formSelector: '[data-component="steps_form"]',
			stepsSelector: '.fields',
			nextBtnSelector: '#nextBtn',
			prevBtnSelector: '#prevBtn'
		});
		stepsForm.init();
	}

	initFormValidate('.form', ['form_search', 'calculator']);
	initAntiSpam('.form', ['form_search', 'calculator']);

// Fancy
	const triggerFancyConsultForm = document.querySelectorAll('.triggerFancyConsultForm');
	triggerFancyConsultForm && triggerFancyConsultForm.forEach( trigger => {
		trigger.addEventListener('click', function(){
			$.fancybox.open({
				src  : '#consult_form',
				type : 'inline'
			  });
		})
	})

	const triggerFancyCalcForm = document.querySelectorAll('.triggerFancyCalcForm');
	triggerFancyCalcForm && triggerFancyCalcForm.forEach( trigger => {
		trigger.addEventListener('click', function(){
			$.fancybox.open({
				src  : '#modalCalcForm',
				type : 'inline'
			  });
		})
	})

	const triggerFancyOrderForm = document.querySelectorAll('.triggerFancyOrderForm');
	triggerFancyOrderForm && triggerFancyOrderForm.forEach( trigger => {
		trigger.addEventListener('click', function(){
			$.fancybox.open({
				src  : '#order_form',
				type : 'inline'
			  });
		})
	})

	const triggerFancyCallbackForm = document.querySelectorAll('.triggerFancyCallbackForm');
	triggerFancyCallbackForm && triggerFancyCallbackForm.forEach( trigger => {
		trigger.addEventListener('click', function(){
			$.fancybox.open({
				src  : '#modalCallbackForm',
				type : 'inline'
			  });
		})
	})


});


$(document).ready(function ($) {


	var showTheContainer = function (slick) {
		$(slick.currentTarget).animate({ opacity: 1}, 300);
		console.log(slick.currentTarget);
	}
	$('#run-slick-slider').on('init', showTheContainer);
	$('#run-slick-slider').slick({
		lidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		// infinite: true,
		autoplay: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		adaptiveHeight: true,
		// centerMode: true,
		focusOnSelect: true,
		lazyLoad: 'ondemand'
	});


	$('#run-slick-gallery').on('init', showTheContainer);
	$('#run-slick-gallery').slick({
		lidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		// infinite: true,
		autoplay: false,
		speed: 500, 
		fade: true,
		cssEase: 'linear',
		adaptiveHeight: true,
		// centerMode: true,
		focusOnSelect: true,
		lazyLoad: 'ondemand',
		asNavFor: '.gallery__nav'
	});
	$('.gallery__nav').on('init', showTheContainer);
	$('.gallery__nav').slick({
		asNavFor: '#run-slick-gallery',
		slidesToShow: 5,
		slidesToScroll: 5,
		dots: false,
		arrows: false,
		centerMode: false,
		focusOnSelect: true,
		infinite: true,
		variableWidth: true
	});



	// $('.photos__main').on('init', showTheContainer);
	// $('.photos__main').slick({
	// 	lidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	dots: false,
	// 	arrows: false,
	// 	// infinite: true,
	// 	autoplay: false,
	// 	speed: 500,
	// 	fade: true,
	// 	cssEase: 'linear',
	// 	adaptiveHeight: true,
	// 	centerMode: true,
	// 	focusOnSelect: true,
	// 	lazyLoad: 'ondemand',
	// 	asNavFor: '.photos__nav'
	// });
	// $('.photos__nav').on('init', showTheContainer);
	// $('.photos__nav').slick({
	// 	asNavFor: '.photos__main',
	// 	slidesToShow: 5,
	// 	slidesToScroll: 5,
	// 	dots: false,
	// 	arrows: false,
	// 	centerMode: false,
	// 	focusOnSelect: true,
	// 	infinite: true,
	// 	variableWidth: true
	// });

	const slickSettings = {
		lidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		infinite: true,
		autoplay: false,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		adaptiveHeight: false,
		centerMode: true,
		focusOnSelect: true
	}

});