import {$} from './utils';
// 1 - Селектор кнопки по нажатию на которую будет выпадать мобильное меню
// 2 - Селектор блока с мобильным меню

const mobileMenu = (triggerSelector, menuSelector) => {
    const trigger = $(triggerSelector);
    const menu = $(menuSelector);
    const layout = $('.fake-layout');

    const showMenu = (e, triggers) => {
        menu.classList.replace('popup_hide', 'popup_show');
        menu.dataset.status = 'show';
        trigger.classList.add('active');
        toggleLayout();

        trigger.off('click', showMenu, false);
        trigger.on('click', hideMenu, false);
        layout.on('click', hideMenuClickOnBg, false);
    }

    const hideMenu = () => {
        menu.classList.replace('popup_show', 'popup_hide');
        menu.dataset.status = 'hide';
        trigger.classList.remove('active');
        toggleLayout();

        trigger.off('click', hideMenu, false);
        layout.off('click', hideMenuClickOnBg, false);
        trigger.on('click', showMenu, false);
    }

    const toggleLayout = () => {
        layout.classList.toggle('hide');
    }

    const hideMenuClickOnBg = (e) => {
        if (e.target.classList.contains('fake-layout')) {
            hideMenu();
        };
    };

    trigger.on('click', showMenu, false);
}

// dropMenu('.menu', 'drop-menu_init');
// 1 - Селектор блока с меню
// 2 - Селектор пункта меню, который будет тригерить событие

const dropMenu = (menuSelector, menuItemSelector) => {
	const menu = document.querySelector(menuSelector);

	const hideDropMenu = function () {
	    this.style.display = 'none';
	};

	const showDropMenu = function (e) {
	    if (!e.target.classList.contains(menuItemSelector)) return;

	    const popup = e.target.nextElementSibling;

	    if (popup) {
	        popup.style.display = 'flex';
	        popup.addEventListener('mouseleave', hideDropMenu, false);
	    }
	};

	if (menu) menu.addEventListener('mouseover', showDropMenu, false);
}

export {
    mobileMenu,
    dropMenu
}