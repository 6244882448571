"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var trunk = function () {
    var items = utils_1.$$('.trunk-slide');
    var content = utils_1.$('.trunk-content');
    var toggleBtn = utils_1.$('#navToggle');
    var toggleFiltrtBtn = utils_1.$('#mFilterToggle');
    var open = function (settings) {
        items.forEach(function (item) {
            if (item.classList.contains(settings.exclude)) {
                return false;
            }
            item.classList.add('open');
            item.classList.remove('close');
        });
        toggleBtn.classList.add('active');
    };
    var close = function () {
        items.forEach(function (item) {
            item.classList.add('close');
            item.classList.remove('open');
        });
        toggleBtn.classList.remove('active');
    };
    var navToggle = function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (content.classList.contains('open')) {
            close();
        }
        else {
            open({ exclude: 'trunk-filter' });
        }
    };
    toggleBtn && toggleBtn.on('click', navToggle);
    var filterToggle = function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (content.classList.contains('open')) {
            close();
        }
        else {
            open({ exclude: 'trunk-nav' });
        }
    };
    toggleFiltrtBtn && toggleFiltrtBtn.on('click', filterToggle);
    var contentClose = function () {
        if (content.classList.contains('open')) {
            close();
        }
    };
    content && content.on('click', contentClose);
};
exports.default = trunk;
