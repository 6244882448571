import {$$} from './utils';

const accordion = (wrapper) => {
    const accordions = $$(wrapper);
    if (!accordions.length) return;

    [...accordions].forEach((item) => {
        accordions.on('click', clickEvent);
    });
}

const clickEvent = ({ target }) => {
    const header = target.closest('[data-component="header"]');

    if (!header) {
        return;
    }

    const body = header.nextElementSibling

    toggle(header, body)
}

const toggle = (header, body) => {
    const status = header.dataset.status;

    body.classList.toggle('hide');

    toggleDataStatus(header, 'opened', 'close');
    toggleDataStatus(body, 'show', 'hide');
    
}

const toggleDataStatus = (el, val1 = 'val1', val2 = 'val2') => {
    el.dataset.status = (el.dataset.status === val1) ? val2 : val1
    return el.dataset.status;
}

export default accordion;