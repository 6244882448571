"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require("./");
var initFormValidate = function (formSelector, excludeForms) {
    //Найти все формы на странице
    var forms = document.querySelectorAll(formSelector);
    if (!forms)
        return;
    // Запустить валидацию для каждой формы.
    // Question: How make better
    // ([...forms] as HTMLFormElement[])
    // Array.from(forms)
    // ([...forms as unknown as []])
    Array.prototype.slice.call(forms)
        .filter(function (form) { return !excludeForms.some(function (selector) { return form.classList.contains(selector); }); })
        .forEach(function (form) { return (new _1.FormValidate({
        form: form,
        inputErrorTextSelector: '.field__error-text',
        formErrorTextSelector: '.form__error-text',
        botErrorTextSelector: '.form__error-bot-text'
    }).init()); });
};
exports.default = initFormValidate;
