"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getCoords(elem) {
    var box = elem.getBoundingClientRect();
    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
}
exports.default = getCoords;
