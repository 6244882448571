import initFormValidate from './initFormValidate.ts';
import FormValidate from './FormValidate.ts';
import inputValidate from './inputValidate.ts';
import {antiSpamCounter, antiSpamCheck, addIsRobotCheckInput} from './antiSpam.ts';

export {
    initFormValidate,
    FormValidate,
    inputValidate,
    antiSpamCounter,
    antiSpamCheck,
    addIsRobotCheckInput
}