"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var antiSpamCounter = function (form) {
    var textarea = form.querySelector('[name = "data[new][wf_message]"]');
    if (!textarea)
        return;
    var counterInput = document.createElement('input');
    counterInput.setAttribute('type', 'hidden');
    counterInput.setAttribute('class', 'antiSpamCounterInput');
    counterInput.setAttribute('name', 'counter');
    counterInput.setAttribute('value', '0');
    form.appendChild(counterInput);
    var addCount = function () {
        var input = form.querySelector('.antiSpamCounterInput');
        var count = parseInt(input.value) + 1;
        input.value = count.toString();
    };
    textarea.addEventListener('keypress', addCount);
};
exports.antiSpamCounter = antiSpamCounter;
var addIsRobotCheckInput = function (form) {
    var checkInput = document.createElement('input');
    checkInput.setAttribute('type', 'hidden');
    checkInput.setAttribute('class', 'isRobotInput');
    checkInput.setAttribute('name', 'isRobot');
    checkInput.setAttribute('value', '');
    form.appendChild(checkInput);
};
exports.addIsRobotCheckInput = addIsRobotCheckInput;
var antiSpamCheck = function (form) {
    var textarea = form.querySelector('[name = "data[new][wf_message]"]');
    var input = form.querySelector('.antiSpamCounterInput');
    if (!textarea)
        return;
    if (parseInt(input.value) >= textarea.value.length) {
        form.dataset.isrobot = 'false';
    }
    else {
        form.dataset.isrobot = 'true';
    }
};
exports.antiSpamCheck = antiSpamCheck;
