"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var matrikaReachGoals = function () {
    // www.jyfa.ru
    var ymId;
    if (document.domain === 'www.jyfa.ru') {
        ymId = 489158;
    }
    else {
        ymId = 70053352;
    }
    var formSubmitBtn = document.querySelectorAll('.formSubmitBtn');
    formSubmitBtn && formSubmitBtn.forEach(function (trigger) {
        trigger.addEventListener('click', function () {
            window.ym(ymId, 'reachGoal', 'formSubmitBtn');
        });
    });
    var objectActionBtn = utils_1.$('#objectActionBtn');
    var objectActionBtnReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'objectActionBtn');
        window.gtag('event', 'Click', {
            'event_category': 'Product_action_btn',
            'event_label': 'Получить консультацию'
        });
        return true;
    };
    objectActionBtn && objectActionBtn.on('click', objectActionBtnReachGoal);
    var orderFormSubmitBtn = utils_1.$('#orderFormSubmitBtn');
    var orderFormSubmitBtnReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'orderFormSubmitBtn');
        window.gtag('event', 'Click', {
            'event_category': 'Webform_send',
            'event_label': 'Отправить'
        });
        return true;
    };
    orderFormSubmitBtn && orderFormSubmitBtn.on('click', orderFormSubmitBtnReachGoal);
    // Клик по тизеру "Подобрать технику"
    var teaserActionBtn = utils_1.$('#teaserActionBtn');
    var teaserActionBtnReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'teaserBtn');
        return true;
    };
    teaserActionBtn && teaserActionBtn.on('click', teaserActionBtnReachGoal);
    // Открыть калькулятор
    var openCalcBtn = utils_1.$('#openCalc');
    var openCalcReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'openCalc');
        return true;
    };
    openCalcBtn && openCalcBtn.on('click', openCalcReachGoal);
    // Открыть форму заказа
    var openOrderForm = utils_1.$('#openOrderForm');
    var openOrderFormReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'openOrderForm');
        return true;
    };
    openOrderForm && openOrderForm.on('click', openOrderFormReachGoal);
    // Открыть форму консультации
    var openConsultForm = utils_1.$('#openConsultForm');
    var openConsultFormReachGoal = function () {
        window.ym(ymId, 'reachGoal', 'openConsultForm');
        return true;
    };
    openConsultForm && openConsultForm.on('click', openConsultFormReachGoal);
};
exports.default = matrikaReachGoals;
