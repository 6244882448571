"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var partnersPopup = function (selComponent, selTrigger, selTarget) {
    var partners = utils_1.$(selComponent);
    if (!partners)
        return false;
    console.log(selTrigger);
    var showPartnerInfo = function (_a) {
        var target = _a.target;
        if (target.dataset.comnponent === selTrigger[0])
            return false;
        var trigger = target;
        if (target.tagName === selTrigger[1])
            trigger = target.parentNode;
        var currentPopup = trigger.querySelector(selTarget);
        if (currentPopup) {
            var popupList = partners.querySelectorAll(selTarget);
            popupList.forEach(function (item) { return popupHide(item); });
            popupShow(currentPopup);
            var top_1 = trigger.offsetTop + trigger.offsetHeight - 20;
            currentPopup.style.top = top_1.toString();
            var left = function (triggerLeft, wrapperWidth, popupWidth) {
                var leftOffset;
                var space = wrapperWidth - popupWidth;
                if (triggerLeft <= space) {
                    leftOffset = triggerLeft;
                }
                else
                    leftOffset = space;
                return leftOffset;
            };
            var leftOffset = left(trigger.offsetLeft, partners.offsetWidth, currentPopup.offsetWidth);
            currentPopup.style.left = leftOffset.toString();
            var arrow = trigger.querySelector('[data-target="arrow"]');
            arrow.style.left = (trigger.offsetLeft + (trigger.offsetWidth / 2) - leftOffset).toString();
            var close_1 = trigger.querySelector('[data-target="close"]');
            var hideOnClose_1 = function () {
                popupHide(currentPopup);
                close_1.off('click', hideOnClose_1);
            };
            close_1 && close_1.on('click', hideOnClose_1);
        }
    };
    var popupShow = function (popup) {
        popup.classList.remove('popup_hide');
        popup.classList.add('popup_show');
    };
    var popupHide = function (popup) {
        popup.classList.remove('popup_show');
        popup.classList.add('popup_hide');
    };
    partners.on('click', showPartnerInfo);
};
exports.default = partnersPopup;
