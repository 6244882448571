"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AntiSpam = /** @class */ (function () {
    function AntiSpam(props) {
        var _this = this;
        this.addIsRobotCheckInput = function (form) {
            var checkInput = document.createElement('input');
            checkInput.setAttribute('type', 'hidden');
            checkInput.setAttribute('class', 'isRobotInput');
            checkInput.setAttribute('name', 'isRobot');
            checkInput.setAttribute('value', '');
            form.appendChild(checkInput);
        };
        this.antiSpamHandler = function () {
            var isRobotInput = _this.form.querySelector('.isRobotInput');
            isRobotInput.value = 'false';
        };
        this.init = function () {
            _this.addIsRobotCheckInput(_this.form);
            _this.form.addEventListener('click', _this.antiSpamHandler, false);
        };
        this.form = props.form;
    }
    return AntiSpam;
}());
exports.default = AntiSpam;
