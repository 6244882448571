import StepsForm from './Stepsform.ts';
import {initFormValidate} from './formValidate';
import {FormValidate} from './formValidate';
import {initAntiSpam} from './antiSpam';

export {
    StepsForm,
    initFormValidate,
    FormValidate,
    initAntiSpam
}