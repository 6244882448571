"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var addBling = function () {
    var $ = document.querySelector.bind(document);
    var $$ = document.querySelectorAll.bind(document);
    Node.prototype.on = window.on = function (name, fn) {
        this.addEventListener(name, fn);
    };
    Node.prototype.off = window.off = function (name, fn) {
        this.removeEventListener(name, fn);
    };
    NodeList.prototype.on = function (name, fn) {
        this.forEach(function (elem) { return elem.on(name, fn); });
    };
    NodeList.prototype.off = function (name, fn) {
        this.forEach(function (elem) { return elem.off(name, fn); });
    };
    return { $: $, $$: $$ };
};
var _a = addBling(), $ = _a.$, $$ = _a.$$;
exports.$ = $;
exports.$$ = $$;
