import {$} from './utils';
import {FormValidate} from './forms';

const calculator = () => {
    const calcForm = $('.calculator');

    if (!calcForm) return false;

    const FV = new FormValidate({
        form: calcForm,
        inputErrorTextSelector: '.field__error-text',
        formErrorTextSelector: '#calcError'
    });

    const state = {
        data: {
            f1: 0,
            f2: 0,
            f3: 0,
            f4: 0,
            f5: 0,
            f6: 0,
            f7: 0,
            f8: 0,
            f9: 0,
            f10: 0,
        },
        result: {
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
            r7: 0,
            r8: 0,
            r9: 0,
            r10: 0,
            r11: 0,
            r12: 0,
            r13: 0,
            r14: 0
        }
    }

    const imps = calcForm.querySelectorAll('input');

    imps.forEach(input => {
        state.data[input.name] = parseInt(input.value)
    });

    const r1 = $('#res_1');
    const r2 = $('#res_2');
    const r3 = $('#res_3');
    const r4 = $('#res_4');
    const r5 = $('#res_5');
    const r6 = $('#res_6');
    const r7 = $('#res_7');
    const r8 = $('#res_8');
    const r9 = $('#res_9');
    const r10 = $('#res_10');
    const r11 = $('#res_11');
    const r12 = $('#res_12');
    const r13 = $('#res_13');
    const r14 = $('#res_14');

    const impsFocusOut = function (e) {
        const input = e.target;

        state.data[input.name] = parseInt(input.value);

        state.result.r1 = fx1(state.data.f2, state.data.f4, state.data.f5);
        state.result.r2 = fx2(state.data.f1, state.data.f4, state.data.f5);
        state.result.r10 = fx10(state.data.f3, state.data.f4, state.data.f5);
        state.result.r3 = fx3(state.result.r10);
        state.result.r4 = fx4();
        state.result.r5 = fx5(state.result.r10, state.data.f7, state.data.f8);
        state.result.r6 = fx6(state.data.f8);
        state.result.r7 = fx7(state.result.r6, state.data.f1, state.result.r10, state.data.f7);
        state.result.r11 = fx11(state.data.f6, state.result.r3, state.result.r4, state.result.r5);
        state.result.r8 = fx8(state.result.r11, state.result.r10);
        state.result.r9 = fx9(state.result.r11, state.result.r1);
        state.result.r12 = fx12(state.result.r9, state.result.r2, state.result.r7, state.data.f9);
        state.result.r13 = fx13(state.result.r12, state.data.f10);
        state.result.r14 = fx14(state.result.r2, state.data.f9, state.data.f10);

        const calcRes = $('#calcResult');
        const calcErr = $('#calcError');

        FV.checkInput(e);

        if(isNaN(state.result.r13)) {
            calcRes.classList.add('hide');
            calcErr.classList.remove('hide');
        } else {
            calcRes.classList.remove('hide')
            calcErr.classList.add('hide')
        }

        addResult()

    }

    const fx1 = (b6,b8,b9) => b6*b8*b9;
    const fx2 = (b6,b8,b9) => (b6*b8*b9)/1000;
    const fx3 = (b30) => b30/25000*350000;
    const fx4 = () => 1500000*0.3;
    const fx10 = (b7,b8,b9) => b7*b8*b9*2;
    const fx5 = (b30,b16,b15) => b30/100*b16*b15;
    const fx6 = (b16) => 44/b16/1000;
    const fx7 = (b22,d6,b30,b15) => (b22*d6*b30/100*b15)/2;
    const fx8 = (b31,b30) => b31/b30;
    const fx9 = (b31,b11) => b31/b11;
    const fx11 = (b14,b19,b20,b21) => (b14*13)+b19+b20+b21;
    const fx12 = (b27,b12,b23,c33) => (b27*b12+b23)*c33;
    const fx13 = (b33,c35) => b33*c35;
    const fx14 = (b12,c33,c35) => b12*c33*c35;

    const addResult = () => {
        r1.innerText = toNormal(state.result.r1);
        r2.innerText = toNormal(state.result.r2);
        r3.innerText = toNormal(state.result.r3);
        r4.innerText = toNormal(state.result.r4);
        r5.innerText = toNormal(state.result.r5);
        r6.innerText = toNormal2(state.result.r6);
        r7.innerText = toNormal(state.result.r7);
        r8.innerText = toNormal(state.result.r8);
        r9.innerText = toNormal(state.result.r9);
        r10.innerText = toNormal(state.result.r10);
        r11.innerText = toNormal(state.result.r11);
        r12.innerText = toNormal(state.result.r12);
        r13.innerText = toNormal(state.result.r13);
        r14.innerText = toNormal(state.result.r14);
    }

    const toNormal = (num) => {
        const n = Math.round(num * 100) / 100;
        const s1 = n.toString().split('.');
        let nStr = n.toString();
        if(s1[0].length > 4) {
            nStr = '';
            const s2 = s1[0].split('');
            let l = s2.length
            let counter = 0;
            while (l > 0) {
                l--
                if(counter%3 === 0 && counter !== 0){
                    nStr = s2[l]+' '+nStr
                } else {
                    nStr = s2[l]+nStr
                }
                counter++
            }
            if(s1[1]) {
                nStr = nStr + '.' + s1[1]
                if(s1[1].length === 1) nStr = nStr + '0'
            }
        }
        return nStr;
    }

    const toNormal2 = (num) => {
        return Math.round(num * 1000) / 1000;
    }

    if (calcForm) calcForm.addEventListener('focusout', impsFocusOut, false);

    const btn = $('#extendBtn');
    const calcExtend = $('#calcExtend');
    const showExtends = () => {
        if(calcExtend.classList.contains('hide')){
            calcExtend.classList.remove('hide');
            btn.innerText = 'Скрыть подробные расчёты'
        } else {
            calcExtend.classList.add('hide');
            btn.innerText = 'Показать подробные расчёты'
        }
    }
    if (btn) btn.addEventListener('click', showExtends, false);
}

export default calculator;